import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { reduxForm } from "redux-form";
import Modal from "react-modal";
import { jobsService } from "../../_services";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import certified from "../../assets/images/certified.png";
import StarRatings from "react-star-ratings";
import fav_heart from "../../assets/images/fav-heart.png";
import unfav_heart from "../../assets/images/unfav-heart.png";

import { ProviderDetail } from "../common/ProviderDetail";
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import loader_image from "../../assets/images/loader.gif";
import { useTranslation } from "react-i18next";


const ServiceProviderProfile = (props: any) => {
  const { t, i18n } = useTranslation();
  const [loginModal, setLoginModal] = useState(false)
  const [reportJobReasonList, setReportJobReasonList] = useState([]) as any;
  const [reportModal, setReportModal] = useState(false)
  const [selectedReport, setSelectedReport] = useState() as any;
  const [reportReason, setReportReason] = useState("") as any;

  const [profiledata, setProfileData] = useState() as any;
  const [setinvite, setInvite] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [activeJobs, setActiveJobs] = useState() as any;
  const [inviteJobId, setinviteJobId] = useState() as any;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [image, setImage] = useState("");
  let category: any;

  function SetInvite() {
    if (localStorage.getItem('access_token')) {
      get_active_jobs(0)
    } else {
      setLoginModal(true)
    }
  }

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;
  const getReportJobReason = () => {
    jobsService.getUserReportReason().then(function (response) {
      setReportJobReasonList(response.data.data)
    }).catch(function (error) {
      console.log("error", error)
      setLoaderstyle({ display: "none" })
      userService.handleError(error);
    });
  }

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function closemodal() {
    setInvite(false);
    setReviewModal(false);
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  useEffect(() => {
    get_data();
    getReportJobReason()
    //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);
  const onReport = () => {
    if (!selectedReport) {
      alert(t("Please select a report option"))
      return
    } if (selectedReport?.type === "Other" && !reportReason) {
      alert(t("Please enter a report reason"))
      return
    }
    let params: any = {}
    params.report_reason_id = selectedReport._id;
    params.reported_user = profiledata._id;
    params.report_reason = reportReason;
    jobsService.onUserReport(params).then(function (response) {
      console.log("responseresponseresponse", response.data)
      setReportModal(false)
      alert(response.data.message);
      setSelectedReport("")
      setReportReason("")
    }).catch(function (error) {
      setReportModal(false)
      console.log("error", error)
      setLoaderstyle({ display: "none" })
      userService.handleError(error);
    });
  }
  function get_data() {
    setLoaderstyle({ display: "block" });
    userService
      .provider_profile(
        props.match.params.id,
        props.match.params.lat,
        props.match.params.lng
      )
      .then(function (response) {
        // console.log(response,"==========profile")
        console.log(response.data);
        setProfileData(response.data.user);
        console.log("------------------", response.data.user);

        category += response.data.user.categories.map((item, key) => (
          <span className={item.name}>{item.name}</span>
        ));
        closemodal();
        setLoaderstyle({ display: "none" });
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function mark_unmark(provider_id, status, e) {
    e.preventDefault();
    let params = {
      provider_id: provider_id,
      status: status,
    };

    setLoaderstyle({ display: "block" });
    userService
      .favMarkUnMark(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });

        get_data();
        // window.location.href = "/";
      })
      .catch(function (error) {
        console.log("errorrrrrr", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const onBlockUser = () => {
    let result = window.confirm(t('Are you sure you want to block this user?'))

    if (result) {
      let param = {
        blocked_by: JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "",
        blocked_user: profiledata._id
      }
      userService.userBlock(param)
        .then(function (response) {
          setLoaderstyle({ display: "none" })
          console.log(response.data);
          alert(response.data?.message);
          window.location.href = "/";
        }).catch(function (error) {
          setLoaderstyle({ display: "none" })
          userService.handleError(error);

        });
    }


  }


  function inviteProvider() {
    setLoaderstyle({ display: "block" });
    let provider_id: any = props.match.params.id;
    let provider_ary: any = [];
    provider_ary.push(provider_id);
    let params = {
      job_id: inviteJobId,
      receiver_ids: provider_ary,
    };

    console.log(params);

    jobsService
      .sendInvite(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("bid accepted");
        console.log(response);
        alert("Invitation Sent Successfully");
        closemodal();
        window.location.href = "/my_jobs";
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function get_active_jobs(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getActiveJobs(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setActiveJobs(response.data.jobs);
        console.log(response.data.jobs);
        if (response.data.jobs.length > 0) {
          setinviteJobId(response.data.jobs[0]._id);
          setInvite(true);
        } else {
          // alert("sorry No jobs available");
          window.location.href = "/add_job"
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }
  var htmltemplateCat: any = [];
  if (profiledata) {
    htmltemplateCat = Object.keys(profiledata.categories).map(function (
      key: any
    ) {
      return (
        <span className={profiledata.categories[key].name}>
          {profiledata.categories[key].name}
        </span>
      );
    });
  }

  function getdocumentURL(category) {
    var document = profiledata.documents.find(obj => {
      return obj.type == category;
    });
    return document ? document.url : null;
  }

  var htmltemplateCatService: any = [];
  if (profiledata) {
    htmltemplateCatService = Object.keys(profiledata.categories).map(function (
      key: any
    ) {
      let subcategory = profiledata.categories[key].sub_categories.map(
        (item, key) => (
          <p>
            <span>{key + 1}</span>
            {item.name}
          </p>
        )
      );

      return (
        <div className="col-md-6">
          <div className="category_content">
            <h2>{profiledata.categories[key].name}</h2>
            {subcategory}
            {getdocumentURL(profiledata.categories[key]._id) ?
              <a target="_blank" href={getdocumentURL(profiledata.categories[key]._id)}>{t("View Document")}</a>
              : null}
          </div>
        </div>
      );
    });
  }
  var htmltemplateGallery: any = [];
  if (profiledata) {
    if (profiledata.gallery.length > 0) {
      htmltemplateGallery = Object.keys(profiledata.gallery).map(function (
        key: any
      ) {
        // console.log("image", key);

        return (
          <div className="col-md-3 col-sm-3">
            <img
              src={profiledata.gallery[key].image}
              onClick={() => {
                setisOpen(true);
                setImage(profiledata.gallery[key].image);
              }}
            />
            {isOpen && (
              <Lightbox
                mainSrc={image}
                onCloseRequest={() => setisOpen(false)}
              />
            )}
          </div>
        );
      });
    } else {
    }
  }
  let { id } = useParams();

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      {profiledata ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${profiledata.profile_image})`,
          }}
        ></section>
      ) : (
        <section className=""></section>
      )}

      {profiledata ? (
        <>
          <section className="service_provider_profile_area">
            <div className="container">
              <div className="profile_main_box text-center">
                <div className="profile_box">
                  <div className="profile_content">
                    <img
                      src={profiledata.profile_image}
                      className="main_profile_image"
                    />

                    <a >
                      <span
                        onClick={(e) => {
                          if (localStorage.getItem('access_token')) {
                            mark_unmark(
                              profiledata._id,
                              profiledata.isFavourite ? "U" : "F",
                              e
                            )
                          } else {
                            setLoginModal(true)
                          }
                        }

                        }
                        className="heart"
                      >
                        {/* <i className={profiledata.isFavourite ? "fa fa-heart" : "fa fa-heart-o"} aria-hidden="true"></i> */}
                        {profiledata.isFavourite ? (
                          <img src={fav_heart} />
                        ) : (
                          <img src={unfav_heart} />
                        )}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="admin_name ">
                  <h1>{profiledata.name} {profiledata.last_name}</h1>

                  <h2>
                    <StarRatings
                      rating={profiledata.avgrating}
                      starRatedColor="#f0bf0c"
                      starDimension="14px"
                      starSpacing="1px"
                      starEmptyColor="#cbd3e4"
                      numberOfStars={5}
                      name="rating"
                    />{" "}
                    {profiledata.reviews > 0 ? (
                      <span
                        className="cursor_pointer"
                        onClick={() =>
                          renderReviewModal(
                            profiledata._id
                          )
                        }
                      >
                        {" "}
                        (
                        {
                          profiledata.reviews
                        }{" "}
                        {t("reviews")})
                      </span>
                    ) : (
                      <span>
                        {" "}
                        ({profiledata.reviews}{" "}
                        {profiledata.reviews < 2 ? t("review") : t("reviews")} ){" "}
                      </span>
                    )}
                    <h3 className="mtop">
                      {profiledata.certified == true && (
                        <span className="certified_provider">
                          <img src={certified} />
                          {t("Certified")}
                        </span>
                      )}

                    </h3>
                  </h2>
                  <div className="job-details-button-box profile-report-btn">

                  </div>
                  {/* <div className="span_box category_name"> */}
                  <div className="dropdown ellipsis-top profile-ellipsis">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a onClick={() => {
                        if (localStorage.getItem('access_token')) {
                          setReportModal(true)
                        } else {
                          setLoginModal(true)
                        }
                      }} className="dropdown-item" href="#">{t("Report Account")}</a>
                      <a onClick={() => {
                        if (localStorage.getItem('access_token')) {
                          onBlockUser()
                        } else {
                          setLoginModal(true)
                        }
                      }} className="dropdown-item" href="#">{t("Block Account")}</a>

                    </div>
                  </div>
                  <ul className="ul-provider-detail">
                    <li>
                      <span className="pull-left">{t("Bio")}</span>
                      <span className="pull-right">{profiledata.bio ? profiledata.bio : t("Not Added")}</span>
                    </li>
                    <li>
                      <span className="pull-left">{t("Account Type")}</span>
                      <span className="pull-right">{profiledata.account_type}</span>
                    </li>
                    <li>
                      <span className="pull-left">{t("Location")}</span>
                      <span className="pull-right">{profiledata?.commune?.length > 0 ? <> {profiledata?.commune + "," + " " + profiledata?.city + "," + " " + profiledata?.country}</>
                        : <> {profiledata?.city + "," + " " + profiledata?.state + "," + " " + profiledata?.country + "," + " " + profiledata?.zip_code}</>
                      }</span>
                    </li>
                    <li>
                      <span className="pull-left">{t("Job Completed")}</span>
                      <span className="pull-right">{profiledata.jobCompleted}</span>
                    </li>

                  </ul>


                </div>
              </div>
            </div>
          </section>

          <section className="service_category">
            <div className="container">
              <div className="service_category_main_box">
                <div className="tab_box">
                  <ul className="nav nav-tabs">
                    <li className="active">
                      <a data-toggle="tab" href="#home" className="tab_1">
                        {t("Services")}
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu1" className="tab_2">
                        {t("Gallery")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content services_tab_content">
                  <div id="home" className="tab-pane fade in active">
                    <div className="row">
                      <h3 className="heading">{t("Service Category")}</h3>
                      <div className="service-category-box">
                        {htmltemplateCatService}
                      </div>

                      {/* <div className="col-md-1"></div>
                     {htmltemplateCatService}
                    <div className="col-md-1"></div> */}
                    </div>
                  </div>
                  <div id="menu1" className="tab-pane fade">
                    {profiledata && profiledata.gallery.length > 0 ? (
                      <div className="row">
                        <h3 className="heading">{t("Gallery")}</h3>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 gallary-image">
                          <div className="row gallary-group inner-gallery">
                            {htmltemplateGallery}
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    ) : (
                      <div className="row">
                        <h3 className="heading">{t("Gallery")}</h3>
                        <div className="no-content">{t("No Images Available")}</div>
                      </div>
                    )}
                  </div>
                  <div className="tab_bottom_button">
                    <a
                      data-toggle="modal"
                      onClick={SetInvite}
                      data-target="#create-profile"
                    >
                      {t("invite for job")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
      <Modal
        ariaHideApp={false}
        isOpen={setinvite}
        onRequestClose={closemodal}
        className={"modal-content  new_modal_content   "}
        portalClassName={""}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          {profiledata && (
            <h4 className="modal-title">
              {t("Invite")} {profiledata.name} {profiledata.last_name}
              <span data-dismiss="modal" className="cross">
                <i
                  onClick={closemodal}
                  className="fa fa-times-circle-o"
                  aria-hidden="true"
                ></i>
              </span>
            </h4>
          )}
        </div>
        <div className="modal-body">
          <h3 className="select_list">
            {t("Select the job from your current job list.")}
          </h3>
          <div className="radio_button_box radio_margin radio-box-over">
            {/* activeJobs */}
            {activeJobs &&
              activeJobs.map((item, index) => {
                return (
                  <label className="radio_box">
                    {item.title}
                    <input
                      type="radio"
                      name="radio"
                      value={item._id}
                      onChange={() => setinviteJobId(item._id)}
                      checked={item._id == inviteJobId ? true : false}
                    />

                    <span className="checkmark"></span>
                  </label>
                );
              })}
          </div>
          <div className="next_bottom">
            <div className="main_btn">
              {profiledata && (
                <a onClick={() => inviteProvider()}>
                  {t("Invite")} {profiledata.name} {profiledata.last_name}
                </a>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={reviewModal}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Reviews")}
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul">
            {reviewAry && reviewAry.reviews.length ?
              reviewAry &&
              reviewAry.reviews.length > 0 &&
              reviewAry.reviews.map((item, index) => {
                return (
                  <div className="review_popup">
                    <img src={item.user_id.profile_image} />

                    <div className="review_content">
                      <h3>
                        {item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span>
                      </h3>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starRatedColor="#f0bf0c"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="#cbd3e4"
                        numberOfStars={5}
                        name="rating"
                      />
                      <p>{item.message}</p>
                    </div>
                  </div>
                );
              })
              :
              <div className='no-data-box no-data-box-review'>
                <img src={require('../../assets/images/no-data.png')} />
                <p>{t("No-reviews-yet")}</p>
              </div>
            }
          </ul>
        </div>
      </Modal>
      {reportModal &&
        <Modal
          ariaHideApp={false}
          isOpen={reportModal}
          onRequestClose={() => setReportModal(false)}
          className={"modal-content   new_modal_content add_new_card "}
          contentLabel="Example Modal"
          style={customStyles1}>
          <div className="modal-body guest-user-popup report-popup-area">
            <div className="row">
              <div className="col-md-12">
                <h5>{t("Report Account")}</h5>
                {
                  reportJobReasonList.length > 0 && reportJobReasonList.map((item) => {
                    return (
                      <div className="report-popup-content" onClick={() => {
                        setReportReason("")
                        setSelectedReport(item)
                      }}>

                        <input type='radio' checked={item._id == selectedReport?._id} />

                        <p className="description">{item.report_reason}</p>
                      </div>

                    )
                  })
                }
                {selectedReport?.type === "Other" &&
                  <div className="mb-3">
                    <textarea
                      value={reportReason}
                      onChange={(e) => setReportReason(e.target.value.trimStart())}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder={t("Enter Reason")}
                    ></textarea>
                  </div>
                }
                <div className="next_bottom filer_buttom-2 " >
                  <div onClick={() => onReport()} className="main_btn">
                    <span>{t("Submit")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      }
      <Modal
        ariaHideApp={false}
        isOpen={loginModal}
        onRequestClose={() => setLoginModal(false)}
        className={
          "modal-content  new_modal_content   "}
        portalClassName={
          ""}
        contentLabel="Example Modal"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 1,
          },

          content: {
            position: 'absolute',
            width: '500px',
            height: 'fit-content',
            top: '200px',
            left: '500px',
            right: '500px',
            bottom: '200px',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}
      >
        {/* <div className="modal-header">
                    <h4 className="modal-title"><span data-dismiss="modal" className="cross"><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true"></i></span></h4>
                </div> */}
        <div className="modal-body guest-user-popup">

          <div className="row">
            <div className='col-md-12'>
              <div className='guest-user-content-area'>
                <img src={require("../../assets/images/guest-image.png")} alt='img' />
                <h6>{t("To perform this action, login is required")}</h6>

                {/* <p>You must first log in</p> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="next_bottom">
                <div className="main_btn border_button">
                  <a onClick={() => setLoginModal(false)}>{t("Cancel")}</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="next_bottom filer_buttom-2">
                <div className="main_btn">
                  <span
                    onClick={() => window.location.href = '/?login=true'}
                  // onClick={() => get_listing(categories, latlng)}
                  >{t("Login")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(ServiceProviderProfile);
